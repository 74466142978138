@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  overscroll-behavior: none;
}

.st0 {
  fill: #a6a6a6;
}
.st1 {
  stroke: #ffffff;
  stroke-width: 0.2;
  stroke-miterlimit: 10;
}
.st1,
.st2 {
  fill: #fff;
}
.st3 {
  fill: url(#SVGID_1_);
}
.st4 {
  fill: url(#SVGID_2_);
}
.st5 {
  fill: url(#SVGID_3_);
}
.st6 {
  fill: url(#SVGID_4_);
}
.st7,
.st8,
.st9 {
  opacity: 0.2;
  enable-background: new;
}
.st8,
.st9 {
  opacity: 0.12;
}
.st9 {
  opacity: 0.25;
  fill: #fff;
}
.gmnoprint {
  display: none;
}
.gauge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 2rem;
}

.gauge {
  width: 80%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}

.gauge-fill {
  height: 100%;
  background-color: #76c7c0;
  transition: width 0.1s ease-in-out;
}

.percentage {
  font-size: 1.5rem;
}

.circular-gauge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.circular-gauge {
  transform: rotate(-90deg);
}

.background-circle {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 10;
}

.foreground-circle {
  fill: none;
  stroke: #005a80;
  stroke-width: 10;
  stroke-dasharray: 314; /* 2 * Math.PI * 50 */
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
}


